<template>
  <v-container
      fluid
      tag="section"
  >
    <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3">
      <v-col cols="12">
        <v-row v-if="user">
          <!-- Trusted User Informations -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="12" class="mb-10">
                <v-row>
                  <v-col cols="auto" align="center" align-self="center">
                    <input id="avatar_input" type="file" hidden @change="uploadAvatar" accept="image/png, image/jpeg, image/jpg">
                    <v-badge
                        bordered
                        color="primary"
                        icon="mdi-cloud-upload"
                        overlap
                        bottom
                        avatar
                    >
                      <v-btn
                          fab
                          x-large
                          @click="chooseFile"
                          :loading="uploading"
                          :disabled="uploading"
                      >
                        <v-avatar size="100">
                          <v-img :src="$store.getters.user.user.avatar ? $store.getters.user.user.avatar : '/assets/img/avatar.png'">
                            <template v-slot:placeholder>
                              <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                              >
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-btn>
                    </v-badge>
                  </v-col>
                  <v-col align-self="center" justify="center">
                    <div class="text-h6 main-font-bold main-title-color">{{ user.firstname }} {{ user.name }} {{ $store.getters.secondUser ? `( Password: ${user.password})` : '' }}</div>
                    <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0 pb-10"><span class="pr-5">{{ $t('personal_information') }}</span>
                      <HelpVideo video="profil" title="Mein Profil"></HelpVideo>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="change-infos">
            <v-row>
              <v-col cols="6" md="3" class="py-2">
                <div class="text-subtitle-1 main-title-color">{{ $t('first_name') }}</div>
                <transition name="slide" mode="out-in">
                  <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'firstname'" @click="activeLoading ? null :active = 'firstname'">{{
                      user.firstname ? user.firstname : $t('tap_to_edit')
                    }}
                  </div>
                  <v-text-field
                      dense
                      hide-details
                      clearable
                      class="pa-0"
                      v-else append-icon="mdi-check-circle"
                      clear-icon="mdi-close-circle"
                      v-model="user.firstname"
                      :loading="activeLoading == 'firstname'"
                      :disabled="activeLoading == 'firstname'"
                      @click:clear="active = false"
                      @click:append="updateProfile('firstname')"
                      @keyup.enter="updateProfile('firstname')"
                  >
                  </v-text-field>
                </transition>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="text-subtitle-1 main-title-color">{{ $t('last_name') }}</div>
                <transition name="slide" mode="out-in">
                  <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'name'" @click="activeLoading ? null :active = 'name'">{{ user.name ? user.name : $t('tap_to_edit') }}</div>
                  <v-text-field
                      dense
                      hide-details
                      clearable
                      class="pa-0"
                      v-else append-icon="mdi-check-circle"
                      clear-icon="mdi-close-circle"
                      v-model="user.name"
                      :loading="activeLoading == 'name'"
                      :disabled="activeLoading == 'name'"
                      @click:clear="active = false"
                      @click:append="updateProfile('name')"
                      @keyup.enter="updateProfile('name')"
                  >
                  </v-text-field>
                </transition>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="text-subtitle-1 main-title-color">{{ $t('address') }}</div>
                <transition name="slide" mode="out-in">
                  <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'address'" @click="activeLoading ? null :active = 'address'"
                       v-html="user.address  ? prepareAddress(user.address) : $t('tap_to_edit')"></div>
                  <v-textarea
                      dense
                      hide-details
                      class="pa-0"
                      rows="3"
                      v-else
                      append-outer-icon="mdi-check-circle"
                      v-model="user.address"
                      :loading="activeLoading == 'address'"
                      :disabled="activeLoading == 'address'"
                      @click:clear="active = false"
                      @click:append-outer="updateProfile('address')"
                  ></v-textarea>
                </transition>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="text-subtitle-1 main-title-color">{{ $t('birthplace') }}</div>
                <transition name="slide" mode="out-in">
                  <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'birthplace'" @click="activeLoading ? null :active = 'birthplace'">
                    {{ user.birthplace ? user.birthplace : $t('tap_to_edit') }}
                  </div>
                  <v-text-field
                      dense
                      hide-details
                      class="pa-0"
                      v-else append-outer-icon="mdi-check-circle"
                      v-model="user.birthplace"
                      :loading="activeLoading == 'birthplace'"
                      :disabled="activeLoading == 'birthplace'"
                      @click:clear="active = false"
                      @click:append-outer="updateProfile('birthplace')"
                      @keyup.enter="updateProfile('birthplace')"
                  ></v-text-field>
                </transition>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="text-subtitle-1 main-title-color">{{ $t('birthdate') }}</div>
                <transition name="slide" mode="out-in">
                  <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'birthdate'" @click="activeLoading ? null :active = 'birthdate'">{{
                      user.birthdate ? user.birthdate : $t('tap_to_edit')
                    }}
                  </div>
                  <v-text-field
                      dense
                      hide-details
                      class="pa-0"
                      placeholder="dd.mm.yyyy"
                      v-else append-outer-icon="mdi-check-circle"
                      v-model="user.birthdate"
                      :loading="activeLoading == 'birthdate'"
                      :disabled="activeLoading == 'birthdate'"
                      @click:clear="active = false"
                      @click:append-outer="updateProfile('birthdate')"
                      @keyup.enter="updateProfile('birthdate')"
                  ></v-text-field>
                </transition>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="text-subtitle-1 main-title-color">{{ $t('age') }}</div>
                <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4">{{ user.age ? user.age : '--' }}</div>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="text-subtitle-1 main-title-color">{{ $t('phone') }}</div>
                <transition name="slide" mode="out-in">
                  <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'phone'" @click="activeLoading ? null :active = 'phone'">{{ user.phone ? user.phone : $t('tap_to_edit') }}</div>
                  <v-text-field
                      dense
                      hide-details
                      class="pa-0"
                      v-else append-outer-icon="mdi-check-circle"
                      v-model="user.phone"
                      :loading="activeLoading == 'phone'"
                      :disabled="activeLoading == 'phone'"
                      @click:clear="active = false"
                      @click:append-outer="updateProfile('phone')"
                      @keyup.enter="updateProfile('phone')"
                  ></v-text-field>
                </transition>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="text-subtitle-1 main-title-color">{{ $t('indent_nr') }}</div>
                <transition name="slide" mode="out-in">
                  <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4" v-if="active != 'indent_nr'" @click="activeLoading ? null :active = 'indent_nr'">{{
                      user.indent_nr ? user.indent_nr : $t('tap_to_edit')
                    }}
                  </div>
                  <v-text-field
                      dense
                      hide-details
                      class="pa-0"
                      v-else
                      append-outer-icon="mdi-check-circle"
                      v-model="user.indent_nr"
                      :loading="activeLoading == 'indent_nr'"
                      :disabled="activeLoading == 'indent_nr'"
                      @click:clear="active = false"
                      @click:append-outer="updateProfile('indent_nr')"
                      @keyup.enter="updateProfile('indent_nr')"
                  ></v-text-field>
                </transition>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CopyToClipboard from 'vue-copy-to-clipboard'
import HelpVideo from "../../../../widgets/HelpVideo"

export default {
  data() {
    return {
      loading: true,
      user: null,
      active: false,
      activeLoading: false,
      uploading: false,
      inviteCode: null,

      inviteEmail: null,
      inviteEmailState: false,
      sendEmail: false
    }
  },
  mounted() {
    this.getMe()
    this.getInvideCode()
  },
  components: {
    HelpVideo,
    CopyToClipboard
  },
  methods: {
    getMe() {
      this.loading = true
      this.$store.dispatch('getMe')
          .then(resp => {
            this.user = resp
            this.user.birthdate = this.convertDate(this.user.birthdate, '/')
            this.loading = false
            this.active = false
            this.activeLoading = false
          })
    },
    getInvideCode() {
      this.loading = true
      this.$store.dispatch('getInvideCode')
          .then(resp => {
            this.inviteCode = resp.token
          })
    },
    updateProfile(tag) {
      this.activeLoading = tag
      const data = JSON.parse(JSON.stringify(this.user));
      data.birthdate = this.convertDate(data.birthdate, '.')
      this.$store.dispatch('updateProfile', data)
          .then(resp => {
            this.getMe()
          })
          .catch(error => {
            this.$helpers.showError(error)
            this.activeLoading = false
          })
    },
    chooseFile() {
      document.getElementById("avatar_input").click()
    },
    uploadAvatar(event) {
      this.uploading = true
      let data = new FormData()
      data.append('avatar', event.target.files[0])
      this.$store.dispatch('uploadAvatar', data)
          .then(resp => {
            this.$store.dispatch('getMe')
                .then(resp => {
                  this.uploading = false
                })
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.uploading = false
          })
    },
    convertDate(date, delimeter) {
      if (date) {
        let temp = date.split(delimeter)
        if (temp.length == 3) {
          return temp[2] + (delimeter == '/' ? '.' : '/') + temp[1] + (delimeter == '/' ? '.' : '/') + temp[0]
        }
        return null
      } else return null
    },
    prepareAddress(address) {
      return address.replace(/(\r\n|\r|\n)/g, '<br>')
    }
  }
};
</script>
