<template>
  <v-container
      fluid
      tag="section"
      class="pt-3 px-8 pb-8"
  >
    <!-- Main Section -->
    <v-row>
      <v-col cols="12" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold main-title-color">{{ $t('trusted_title') }}</p>
        <div class="subtitle-1 font-weight-light main-grey">
          {{ $t('add_trusted_user_desc') }}
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12">
        <!-- Data Tabs -->
        <v-row class="mt-1">
          <v-col cols="12">
            <v-row v-if="!usersLoading">
              <v-col cols="12">
                <v-hover v-slot="{hover}">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">{{ $t('name') }}</th>
                        <th class="text-left">{{ $t('email') }}</th>
                        <th class="text-center">{{ $t('actions') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(user, i) in users" :key="user.id"
                          :color="(user.status != 'requested' && (hover || (selectedUser ? selectedUser.id == user.id : false))) ? 'primary' : user.status == 'requested' ? 'grey' : 'white'"
                          :class="{'cursor-pointer': user.status != 'requested'}" @click="user.status != 'requested' ? selectedUser = user : null">
                        <td>{{ i + 1 }}</td>
                        <td>{{ user.first_name }} {{ user.last_name }}</td>
                        <td>{{ user.email }}</td>
                        <td class="text-center">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  class="ml-2"
                                  x-small
                                  fab
                                  color="grey"
                                  bottom
                                  left
                                  @click="editTrustedUser(user)"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <v-icon color="white">mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>Editieren</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  class="ml-2"
                                  x-small
                                  fab
                                  color="grey"
                                  bottom
                                  left
                                  :disabled="deleteUserLoading"
                                  :loading="deleteUserLoading"
                                  @click="selectedUser = user; resendEmailConfirmDialog = true"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <v-icon color="white">mdi-email</v-icon>
                              </v-btn>
                            </template>
                            <span>E-Mail erneut versenden</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  class="ml-2"
                                  x-small
                                  fab
                                  color="grey"
                                  bottom
                                  left
                                  :disabled="deleteUserLoading"
                                  :loading="deleteUserLoading"
                                  @click="selectedUser = user; deleteConfirmDialog = true"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <v-icon color="white">mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Löschen</span>
                          </v-tooltip>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-hover>
              </v-col>

              <!-- Add New Button -->
              <v-col justify="center" align="center" cols="12" class="mt-10 mb-16">
                <v-btn fab x-large color="primary" @click="openAddTrusterDialog()">
                  <v-icon x-large>mdi-account-plus</v-icon>
                </v-btn>
                <p class="text-subtitle-1 mt-5">{{ $t('add_user') }}</p>
              </v-col>

            </v-row>
            <v-row v-else class="mb-5 mt-5" :key="0">
              <v-col cols="12">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <transition name="slide" mode="out-in">
        <v-col cols="12" class="mt-10" v-if="activeTab === 'users' && selectedUser">
          <v-row>
            <!-- Trusted User Informations -->
            <v-col cols="9">
              <v-row>
                <v-col cols="12">
                  <div class="text-h6 main-font-bold main-title-color">{{ selectedUser.first_name }} {{ selectedUser.last_name }}</div>
                  <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0">{{ $t('personal_information') }}</div>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('name') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.first_name }} {{ selectedUser.last_name }}</div>
                    </v-col>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('email') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.email }}</div>
                    </v-col>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('address') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.street }} {{ selectedUser.house_number }}<br>{{ selectedUser.zip }} {{ selectedUser.city }}</div>
                    </v-col>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('phone') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.phone || '---' }}</div>
                    </v-col>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('birthdate') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.bdate || '---' }}</div>
                    </v-col>
                    <v-col cols="3">
                      <div class="text-subtitle-1 main-title-color">{{ $t('gender') }}</div>
                      <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ $t(selectedUser.gender == 'other' ? 'other_gender' : selectedUser.gender) }}</div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </transition>
    </v-row>

    <!-- Delete Trusted User Confirm Dialog -->
    <v-dialog
        v-model="deleteConfirmDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('are_you_sure') }}
        </v-card-title>

        <v-card-text>
          {{ $t('delete_trusted_alert') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="deleteTrustedUser(selectedUser.id)"
          >
            {{ $t('yes') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="deleteConfirmDialog = false"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Resend Truster E-Mail -->
    <v-dialog
        v-model="resendEmailConfirmDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('are_you_sure') }}
        </v-card-title>

        <v-card-text>
          {{ $t('resend_email_alert') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="resendEmail()"
          >
            {{ $t('yes') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="resendEmailConfirmDialog = false; selectedGroup = null;"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Trusted Group Confirm Dialog -->
    <v-dialog
        v-model="deleteGroupConfirmDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('are_you_sure') }}
        </v-card-title>

        <v-card-text>
          {{ $t('delete_group_alert') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="deleteTrustedGroup(selectedGroup)"
          >
            {{ $t('yes') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="deleteGroupConfirmDialog = false; selectedGroup = null;"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Trusted User Dialog -->
    <v-dialog
        v-model="addTrustedUserDialog"
        max-width="700"
    >
      <v-card
          class="pa-4"
      >
        <v-card-title class="text-h5 white--text primary" v-html="(editingTrustedUser  ? $t('edit_user') : $t('add_user')) + ':'">
        </v-card-title>
        <v-card-text class="primary" v-if="!editingTrustedUser">
                    <span class="white--text">
                        {{ $t('add_trusted_user_desc') }}
                    </span>
        </v-card-text>
        <v-card-text class="mt-5">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :placeholder="$t('first_name')"
                  v-model="newUser.first_name"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :placeholder="$t('last_name')"
                  v-model="newUser.last_name"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :placeholder="$t('email')"
                  v-model="newUser.email"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :placeholder="$t('phone')"
                  v-model="newUser.phone"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :placeholder="$t('street')"
                  v-model="newUser.street"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :placeholder="$t('house_number')"
                  v-model="newUser.house_number"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :placeholder="$t('zip')"
                  v-model="newUser.zip"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :placeholder="$t('city')"
                  v-model="newUser.city"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  :items="[
                                    { text: $t('male'), value: 'male' },
                                    { text: $t('female'), value: 'female' },
                                    { text: $t('other_gender'), value: 'other' },
                                ]"
                  v-model="newUser.gender"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  :placeholder="$t('gender')"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  class="pa-0"
                  :placeholder="$t('birthdate')"
                  v-model="newUser.bdate"
                  :loading="addUserLoading"
                  :disabled="addUserLoading"
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
              :disabled="!newUser.first_name ||
                        !newUser.last_name ||
                        !newUser.email ||
                        !newUser.phone ||
                        !newUser.street ||
                        !newUser.house_number ||
                        !newUser.zip ||
                        !newUser.city ||
                        !newUser.gender ||
                        !newUser.bdate ||
                        addUserLoading"
              :loading="addUserLoading"
              color="primary"
              :style="{'width': $vuetify.breakpoint.smAndDown ? '100%' : ''}"
              @click="addTrustedUser"
          >
            {{ editingTrustedUser ? $t('edit_trusted') : $t('add_to_trusted') }}
            <v-icon right v-if="!editingTrustedUser">
              mdi-plus
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      usersLoading: true,
      groupsLoading: true,
      certificateUploadersLoading: false,
      deleteConfirmDialog: false,
      resendEmailConfirmDialog: false,
      deleteGroupConfirmDialog: false,
      addTrustedUserDialog: false,

      users: null,
      groups: null,
      certificateUploaders: null,
      group: '0',

      entries: [],
      isLoading: false,
      addUserLoading: false,
      newUser: {},
      search: null,
      items: [],
      groupItems: [],

      deleteUserLoading: false,
      selectedUser: null,

      deleteGroupLoading: false,
      selectedGroup: null,

      newGroup: null,
      groupAddLoading: false,

      activeTab: 'users',

      editingTrustedUser: false
    };
  },
  mounted() {
    this.getTrustedUsers()
  },
  methods: {
    getTrustedUsers() {
      this.usersLoading = true
      this.$store.dispatch('getTrustedUsers')
          .then(resp => {
            this.users = resp
            this.usersLoading = false
          })
    },

    openAddTrusterDialog() {
      this.newUser = {}

      this.editingTrustedUser = false
      this.addTrustedUserDialog = true
    },

    addTrustedUser() {
      if (
          !this.newUser.first_name ||
          !this.newUser.last_name ||
          !this.newUser.email ||
          !this.newUser.phone ||
          !this.newUser.street ||
          !this.newUser.house_number ||
          !this.newUser.zip ||
          !this.newUser.city ||
          !this.newUser.gender ||
          !this.newUser.bdate
      ) return

      this.addUserLoading = true

      let type = this.editingTrustedUser ? 'editTrustedUser' : 'addTrustedUser'

      this.$store.dispatch(type, this.newUser)
          .then(resp => {
            this.addUserLoading = false
            this.newUser = {}
            this.$helpers.showMessage('success', this.$t('trusted_added_success'))
            this.getTrustedUsers()
            this.addTrustedUserDialog = false
          })
          .catch(err => {
            this.addUserLoading = false
            this.$helpers.showError(err)
          })
    },

    editTrustedUser(user) {
      this.newUser = user

      this.addTrustedUserDialog = true
      this.editingTrustedUser = true
    },

    resendEmail() {
      if (this.selectedUser) {
        this.deleteUserLoading = false
        console.log(this.selectedUser)
        this.$store.dispatch('resendEmailForTruster', this.selectedUser.id)
            .then(resp => {
              console.log(resp)
              this.deleteUserLoading = false
              this.resendEmailConfirmDialog = false
              this.selectedUser = null
              this.$helpers.showMessage('success', this.$t('truster_email_resent'))
              this.getTrustedUsers()
            })
            .catch(err => {
              this.deleteUserLoading = false
              this.$helpers.showError(err)
            })
      } else {
        this.resendEmailConfirmDialog = true
      }
    },

    deleteTrustedUser(id) {
      if (this.selectedUser) {
        this.deleteUserLoading = true
        this.$store.dispatch('deleteTrustedUser', this.selectedUser.id)
            .then(resp => {
              this.deleteUserLoading = false
              this.deleteConfirmDialog = false
              this.selectedUser = null
              this.$helpers.showMessage('success', this.$t('trusted_removed_success'))
              this.getTrustedUsers()
            })
            .catch(err => {
              this.deleteUserLoading = false
              this.$helpers.showError(err)
            })
      } else {
        this.selectedUser = id
        this.deleteConfirmDialog = true
      }
    },

    addTrustedGroup() {
      if (!this.newGroup) return

      this.groupAddLoading = true

      this.$store.dispatch('addTrustedGroup', {name: this.newGroup})
          .then(resp => {
            this.groupAddLoading = false
            this.newGroup = null
            this.getTrustedGroups()
          })
          .catch(err => {
            this.groupAddLoading = false
            this.$helpers.showError(err)
          })
    },

    deleteTrustedGroup(id) {
      if (this.selectedGroup) {
        this.deleteGroupConfirmDialog = false
        this.selectedGroup = null
        this.deleteGroupLoading = true
        this.$store.dispatch('deleteTrustedGroup', id)
            .then(resp => {
              this.deleteGroupLoading = false
              this.selectedGroup = null
              this.$helpers.showMessage('success', this.$t('group_removed'))
              this.getTrustedGroups()
            })
            .catch(err => {
              this.deleteGroupLoading = false
              this.$helpers.showError(err)
            })
      } else {
        this.selectedGroup = id
        this.deleteGroupConfirmDialog = true
      }
    },
  }
};
</script>
