<template>
  <section>
    <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true"/>
    <v-container class="text-container">
      <v-row>
        <v-col
            cols="12"
            md="7"
            justify="center"
        >
          <h1>Die Geras24 Notfallbox</h1>
          <div class="main-font">
            Mit der Notfallbox von Geras24 sind
            in einem Notfall schnell alle wichtigen Informationen für die Ersthelfer griffbereit. In Situationen, in der jede Sekunde zählt, kann eine Notfallbox Leben retten.<br><br>
            Notfallmappe, Patientenverfügung oder Vorsorgevollmacht sind durchaus sinnvolle Maßnahmen. Doch gerade in Stresssituationen sind Patienten zumeist nicht in der Lage, zu reagieren oder klare Anweisungen zu
            geben. Genau an dieser Stelle kommt die Geras24 Notfallbox zum Einsatz.
            Alle wichtigen Medikamente und ihre Aufbewahrungsorte sowie Kontaktpersonen sind in der Notfallbox gelistet.<br><br>
            Die Notfallbox wird mit dem ausgefüllten Informationsblatt im Kühlschrank aufbewahrt. Ein kleiner Aufkleber an der Eingangstür weist den Ersthelfer sofort auf die Box hin. Unter den Notarzthelfern ist dieses
            Verfahren gängige Praxis. Viele Menschenleben konnten so gerettet werden.
            <br><br><b>Wichtiger HINWEIS:</b><br> Alle im Haushalt lebenden Personen müssen ein Informationsblatt ausfüllen. Die ausgefüllten Formulare werden dann in der Geras24 Notfallbox aufbewahrt.
          </div>
          <div class="pt-5">
            <v-btn
                elevation="5"
                min-width="0"
                x-large
                color="primary"
                @click="redirectToShop()"
            >
              <span>Geras24 Notfallbox kaufen</span>
            </v-btn>
          </div>
        </v-col>
        <v-col
            cols="12"
            md="5"
            align="center"
            justify="center"
        >
          <v-img src="/assets/img/notfallbox.jpg" width="500"></v-img>
        </v-col>
      </v-row>
    </v-container>

    <Footer
        :showLogin="showLogin"
        :showRegister="showRegister"
        @close-login-modal="showLogin = false"
        @close-register-modal="showRegister = false"
    />
  </section>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";

export default {
  components: {
    Header,
    Footer,
    LoginDialog,
    RegisterDialog
  },
  data() {
    return {
      showLogin: false,
      showRegister: false
    }
  },
  created() {
    this.setMetaTags()
  },
  methods: {
    redirectToShop() {
      window.open('https://shop.geras24.de/produkt/notfallbox-notfalldose/', '_blank');
    }
  }
}
</script>
