<template>
  <section>

    <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true"/>

    <v-row class="ma-0 pb-0">
      <!-- Icon Area -->
      <v-col v-if="!$helpers.isMobile()" cols="8" justify="center" align-self="center" align="center">
        <v-img src="/assets/img/login.jpg" width="400px"></v-img>
      </v-col>
      <!-- Form Area -->
      <v-col cols="12" md="4" class="auth-bg login-bg" :class="{'pt-10': $helpers.isMobile()}">
        <v-container v-if="$route.name == 'login' || $route.name == 'verifyEmail'" class="auth-form">
          <v-row class="mt-4">
            <h1 class="auth-title">{{ $t('login') }}</h1>
          </v-row>

          <v-row class="mt-4">
            <v-text-field
                :label="$t('username_or_email')"
                :disabled="loading"
                filled
                rounded
                :name="Math.random()"
                v-model="form.username"
                @keyup.enter="login"
            ></v-text-field>
          </v-row>
          <v-row class="mt-4">
            <v-text-field
                :label="$t('password')"
                type="password"
                :disabled="loading"
                filled
                rounded
                :name="Math.random()"
                v-model="form.password"
                @keyup.enter="login"
            ></v-text-field>
          </v-row>
          <v-row class="mt-2" justify="center">
            <v-col>
              <v-btn
                  :loading="loading || (providerLoading ? true : false)"
                  :disabled="loading || (providerLoading ? true : false)"
                  color="white"
                  block
                  rounded
                  @click="login"
              >
                Login
                <!--                            <v-icon light>-->
                <!--                                mdi-lock-->
                <!--                            </v-icon>-->
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-4" justify="center">
            <v-col>
              <v-btn
                  color="error"
                  dark
                  block
                  rounded
                  @click="loginWithProvider('google')"
                  :disabled="providerLoading ? true : false"
                  :loading="providerLoading == 'google' ? true : false"
              >
                {{ $t('google') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                  color="primary"
                  dark
                  block
                  rounded
                  @click="loginWithProvider('facebook')"
                  :disabled="providerLoading ? true : false"
                  :loading="providerLoading == 'facebook' ? true : false"
              >
                {{ $t('facebook') }}
              </v-btn>
            </v-col>

          </v-row>
          <v-row class="mt-2">
            <v-col justify="center" align="center">
              <p>
                <router-link :to="{name: 'register'}" class="primary--text">{{ $t('create_account') }}</router-link>
              </p>
              <p>
                <router-link :to="{name: 'resetPassword'}" class="primary--text">{{ $t('forget_password') }}</router-link>
              </p>
            </v-col>
          </v-row>
        </v-container>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-col>
    </v-row>

    <Footer
        :showLogin="showLogin"
        :showRegister="showRegister"
        @close-login-modal="showLogin = false"
        @close-register-modal="showRegister = false"
    />

  </section>
</template>

<script>

import Vue from "vue";
import Header from "../Header";
import Footer from "../Footer";
import LoginDialog from "../../../widgets/LoginDialog";
import RegisterDialog from "../../../widgets/RegisterDialog";

export default {
  components: {
    Header,
    Footer,
    LoginDialog,
    RegisterDialog
  },
  data() {
    return {
      loading: false,
      form: {
        username: null,
        password: null,
      },
      providerLoading: false,
      showLogin: false,
      showRegister: false,
    }
  },
  mounted() {
    if (this.$route.name == 'authVerify') {
      // Get Parameters
      let finalQuery = null
      for (const key in this.$route.query) {
        if (Object.hasOwnProperty.call(this.$route.query, key)) {
          const value = this.$route.query[key];
          finalQuery = (finalQuery ? (finalQuery + '&') : '') + key + '=' + value
        }
      }
      // Verify Route
      this.loading = true
      this.$store.dispatch('verifyEmail', finalQuery)
          .then(resp => {
            Vue.$toast.success(this.$t('success_verify'))
            if (this.$route.query.plan_id) this.$router.push({name: 'login', params: {id: this.$route.query.plan_id}})
            else this.$router.push({name: 'login'})
          })
          .catch(err => {
            this.loading = false
            this.$helpers.showError(err)
          })
    } else if (this.$route.name == 'providerCallback') {
      // Get Parameters
      let finalQuery = null
      for (const key in this.$route.query) {
        if (Object.hasOwnProperty.call(this.$route.query, key)) {
          const value = this.$route.query[key];
          finalQuery = (finalQuery ? (finalQuery + '&') : '') + key + '=' + value
        }
      }
      // Verify Route
      this.loading = true
      this.$store.dispatch('providerLoginCallback', {data: finalQuery, provider: this.$route.params.provider})
          .then(resp => {
            Vue.$toast.success(this.$t('success_login'))
            this.otpStatus = resp.user.otp_status

            if (this.otpStatus == 'disabled') {
              if (this.$route.query.redirect)
                window.location = this.$route.query.redirect
              else
                this.$router.go({name: 'home'})
            } else this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.$helpers.showError(err)
          })
    }
  },
  methods: {
    login() {
      if (!this.form.username) {
        Vue.$toast.error(this.$t('enter_username'))
        return
      }
      if (!this.form.password) {
        Vue.$toast.error(this.$t('enter_password'))
        return
      }
      this.loading = true
      this.$store.dispatch('login', this.form)
          .then(resp => {
            this.form = {
              username: null,
              password: null,
            }
            this.action = resp.action || null

            if (this.action == 'REGISTER_OTP' || this.action == 'REQUIRE_OTP') {
              this.$router.push({name: 'otp', params: {action: resp, redirect: this.$route.query.redirect || null, id: this.$route.params.id || null}})
            } else {
              Vue.$toast.success(this.$t('success_login'))
              // window.location.href = '/dashboard/profile/info'
              // this.$router.go({name: 'profile'})

              if (this.$route.query.redirect)
                window.location = this.$route.query.redirect
              else if (this.$route.params.id)
                this.$router.push({name: 'profilePlans', params: {id: this.$route.params.id}})
              else
                this.$router.push({name: 'profile'})

            }
          })
          .catch(err => {
            this.loading = false
            this.$helpers.showError(err)
          })
    },

    loginWithProvider(provider) {
      this.providerLoading = provider
      this.$store.dispatch('providerLogin', provider)
          .then(resp => {
            this.$helpers.showMessage('info', this.$t('redirect_to_provider'))
            window.location.href = resp.url
          })
          .catch(err => {
            this.providerLoading = false
            this.$helpers.showError(err)
          })
    }
  }
}
</script>
