<template>
  <v-container
      fluid
      tag="section"
  >
    <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3">
      <v-col cols="12" md="5">
        <div class="text-h5 main-font-bold main-title-color">{{ $t('your_abos') }}</div>
        <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0">{{ $t('your_abos_subtitle') }}</div>
      </v-col>
      <v-col cols="12" md="7" align="center" justify="center">
        <v-row v-if="$store.getters.user.user.subscription" align="right" justify="end">
          <!-- Duration -->
          <v-col align="center" justify="center">
            <v-progress-circular
                class="plan-progress-circular"
                :rotate="360"
                :size="95"
                :width="8"
                :value="($store.getters.user.user.subscription.remainingDays * 100) / $store.getters.user.user.subscription.duration"
                color="teal"
            >
              <span class="">{{ $store.getters.user.user.subscription.remainingDays }} {{ $t('days') }}</span>
            </v-progress-circular>
            <div class="text-subtitle-1 mt-2">{{ $t('remaining_days') }}</div>
          </v-col>
          <!-- File Size -->
          <v-col align="center" justify="center">
            <v-progress-circular
                class="plan-progress-circular"
                :rotate="360"
                :size="95"
                :width="8"
                :value="((getUsage('files.size') * 100) / getUsageMain('files.size') - 100) * (-1)"
                color="teal"
            >
              <span>{{ getUsageMain('files.size') - getUsage('files.size') }} MB</span>
            </v-progress-circular>
            <div class="text-subtitle-1 mt-2">{{ $t('storage') }}</div>
          </v-col>
          <!-- Trusters Counter -->
          <v-col align="center" justify="center">
            <v-progress-circular
                class="plan-progress-circular"
                :rotate="360"
                :size="95"
                :width="8"
                :value="((getUsage('trusted.count') * 100) / getUsageMain('trusted.count') - 100) * (-1)"
                color="teal"
            >
              <span>{{ getUsage('trusted.count') }} / {{ getUsageMain('trusted.count') }}</span>
            </v-progress-circular>
            <div class="text-subtitle-1 mt-2">{{ $t('trusted_limit') }}</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3">
      <v-col cols="12">
        <div class="text-h6 main-font-bold main-title-color">{{ $t('your_abos_list') }}</div>
      </v-col>
      <v-col cols="12" v-if="!paysLoading">
        <v-simple-table v-if="pays.length && $store.getters.user.user.subscription">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">#</th>
              <th class="text-left">{{ $t('abonnement') }}</th>
              <th class="text-left">{{ $t('amount') }}</th>
              <th class="text-left">{{ $t('date') }}</th>
              <th class="text-left">{{ $t('status') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(pay, i) in pays" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ pay.plan.name }}</td>
              <td>{{ pay.amount }} {{ pay.currency }}</td>
              <td>{{ pay.created_at }}</td>
              <td>
                <v-chip color="success">
                  {{ $t('activated') }}
                </v-chip>
              </td>
              <td>
                <v-btn
                    :disabled="subscribeLoading"
                    :loading="subscribeLoading"
                    color="primary"
                    @click="redeemDialog = true"
                >
                  Weiteren Code einlösen
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-alert
            v-if="!paysLoading && !$store.getters.user.user.subscription"
            color="primary"
            class="white--text"
            elevation="5"
        >
          <div class="d-flex align-center justify-space-between flex-column flex-sm-row">
            <span class="mb-3 mb-sm-0 text-center text-sm-left">{{ $t('no_abos') }}</span>
            <v-btn
                :disabled="subscribeLoading"
                :loading="subscribeLoading"
                class="ml-sm-2"
                @click="redeemDialog = true"
            >
              Code einlösen
            </v-btn>
          </div>
        </v-alert>
      </v-col>
      <div class="d-flex flex-grow-1" v-if="!paysLoading && !$store.getters.user.user.subscription">
        <Pricing full-width @open-register-modal="openRegisterModal"></Pricing>
      </div>
    </v-row>

    <v-dialog
        v-model="redeemDialog"
        max-width="400"
    >
      <v-card>
        <v-card-text class="text--primary">
          <v-card-title class="pl-0 pr-0">
            <span>Abo aktivieren</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="redeemDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-text-field
              class="text-center mb-4"
              placeholder="Code"
              v-model="licenseCode"
              :loading="sending"
              :disabled="sending"
              outlined
              dense
              hide-details
          ></v-text-field>
          <v-btn
              color="primary"
              block
              :disabled="subscribeLoading"
              :loading="subscribeLoading"
              @click="redeemGivenLicenseCode(licenseCode)"
          >
            Abo aktivieren
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <register-dialog :price-model="pricingPlan" @close-register-modal="showRegister = false" @open-popup-blocked-modal="showPopupBlocked = true" v-model="showRegister"></register-dialog>
    <popup-blocked-dialog :price-model="pricingPlan" v-model="showPopupBlocked"></popup-blocked-dialog>

  </v-container>
</template>

<script>
import RegisterDialog from "../../../../widgets/RegisterDialog";
import PopupBlockedDialog from "../../../../widgets/PopupBlockedDialog";
import Pricing from "@/widgets/Pricing.vue";

export default {
  components: {
    Pricing,
    RegisterDialog,
    PopupBlockedDialog
  },
  data() {
    return {
      redeemDialog: false,
      paysLoading: true,
      subscribeLoading: false,
      licenseCode: null,
      sending: false,
      showRegister: false,
      showPopupBlocked: false,
      pricingPlan: null,
      pays: {}
    }
  },
  mounted() {
    this.getPays()
  },
  methods: {
    getPays() {
      this.paysLoading = true
      this.$store.dispatch('getPays')
          .then(resp => {
            this.pays = resp
            this.pays.splice(1);
            this.paysLoading = false
          })
    },
    getUsage(code) {
      let usages = this.$store.getters.user.user.subscription.usages

      for (let index = 0; index < usages.length; index++) {
        const usage = usages[index];

        if (usage.code == code) return usage.used
      }
    },
    getUsageMain(code) {
      let usages = this.$store.getters.user.user.subscription.usages

      for (let index = 0; index < usages.length; index++) {
        const usage = usages[index];

        if (usage.code == code) return usage.limit
      }
    },
    redeemGivenLicenseCode(code) {
      this.subscribeLoading = true

      this.$store.dispatch('redeemGivenCode', {
        code: code
      })
          .then(resp => {
            if (resp.result) {
              if (resp.is_promo_code && typeof fbq !== "undefined") window.fbq('track', 'Lead')
              this.$helpers.showMessage('success', this.$t('redeem_code_success'))

              setTimeout(() => {
                location.reload()
              }, 1000)
            } else {
              this.$helpers.showMessage('error', this.$t('redeem_code_error'))
            }
          })
          .catch(err => {
            this.$helpers.showError(err)
          })
          .finally(() => {
            this.subscribeLoading = false
            this.redeemDialog = false
          })
    },
    openRegisterModal(plan) {
      this.pricingPlan = plan
      this.showRegister = true
    },
  }
};
</script>

<style scoped>
.plan-progress-circular span {
  max-width: 80px;
}
</style>
