<template>
  <section id="faq">
    <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true"/>

    <v-container class="mb-16">
      <v-row class="mt-10">
        <v-col cols="12">
          <h1>Hilfe</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="main-font">
          Hier findest Du Hilfe zu allen Themen rund um Geras24.
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels>
            <v-expansion-panel
                v-for="(faq,i) in faqs"
                :key="i"
            >
              <v-expansion-panel-header>
                {{ faq.question }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <span v-html="faq.answer">{{ faq.answer }}</span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <Footer
        :showLogin="showLogin"
        :showRegister="showRegister"
        @close-login-modal="showLogin = false"
        @close-register-modal="showRegister = false"
    />

  </section>
</template>

<script>

import Header from "./Header.vue";
import Footer from "../../components/public/Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";

export default {
  components: {
    Header,
    Footer,
    LoginDialog,
    RegisterDialog
  },
  data() {
    return {
      faqs: [
        {
          question: 'Was ist Geras24?',
          answer: 'Geras ist der griechische Gott des hohen Alters. Wir alle wünschen uns ein langes, glückliches Leben. Doch wir alle werden mit der Zeit mit dem Tod konfrontiert. Menschen, die wir lieben, gehen eines Tages und irgendwann auch wir selbst. Den Zeitpunkt dafür kann niemand vorhersehen. Aber jeder kann sich bestmöglich vorbereiten. Geras24 ist ein digitaler Ort, der es ermöglicht, alle wichtigen Dokumente wie Vorsorgevollmachten, Versicherungen und Verträge zentral und sicher zu hinterlegen. Neben formellen Unterlagen finden auch persönliche Botschaften und Erinnerungen hier einen geeigneten Aufbewahrungsplatz. Das Leben in vollen Zügen genießen – mit der richtigen Vorsorge bei Geras24.'
        },
        {
          question: 'Checkliste',
          answer: 'Plötzlich ist jemand gestorben und du musst dich als Angehöriger um die Abwicklung kümmern. Hier findest du eine kostenlose Checkliste, die dir zeigt woran du nach einem Todesfall denken musst. Was ist zu erledigen, was ist zu kündigen, welche Lebensversicherung zahlt? Als Mitglied bei Geras24 bleibt den Angehörigen genau diese Arbeit erspart, denn das meiste kann man zu Lebzeiten schon erledigen. Wir helfen dir dabei, werde jetzt Mitglied.'
        },
        {
          question: 'Persönliche Botschaften',
          answer: 'Hinterlasse deinen Liebsten eine persönliche Nachricht. Neben bürokratischen Angelegenheiten kannst du Videos und Botschaften hinterlegen – das macht Geras24 so einzigartig. Nach deinem Ableben erhalten deine Hinterbliebenen Zugang zu den für sie bestimmten Nachrichten. Ein kurzer Moment der Lebendigkeit – eine Erinnerung für die Ewigkeit.'
        },
        {
          question: 'Persönliche Dokumente / Tresor',
          answer: 'Der Tresor gehört zu den Kernprodukten von Geras24. Hier kannst du alle wichtigen Dokumente hochladen – seien es Unterlagen wie Kontoauszüge für die eigene Buchhaltung, Versicherungspolicen oder Arztrechnungen. Zugleich kannst du heute schon an morgen denken und gemeinsam mit Geras24 vorsorgen. Speichere im Tresor jene Dokumente, auf die deine Hinterbliebenen im Falle eines schweren Unfalls oder Todes zugreifen können. Deinen Lieben ersparst du damit die aufwändige Suche nach Unterlagen, die schnell griffbereit sein müssen.'
        },
        {
          question: 'Der letzte Wunsch',
          answer: 'Mit dem letzten Wunsch triffst du die Entscheidung, was nach deinem Ableben geschehen soll. Diese Wünsche betreffen in erster Linie deine Beerdigung. Deine Familie kann alles so gestalten, wie du es dir vorstellst. Indem du vorab Entscheidungen fällst, nimmst du deinen Liebsten einen großen Ballast von den Schultern und gibst zugleich die Gewissheit, nach deinen Wünschen zu handeln. Werde Mitglied bei Geras24 und plane frühzeitig.'
        },
        {
          question: 'Up',
          answer: 'Up – das Herzstück von Geras24 und Kommunikationsplattform der Zukunft. Deine Freunde können sich hier nach deinem Tod digital miteinander austauschen, gemeinsam trauern und in Erinnerungen schwelgen. Jeder hat die Möglichkeit, eine persönliche Widmung in dein Kondolenzbuch einzutragen. Bei Up lebst du auf besondere Weise weiter. Du möchtest mehr erfahren? Werde jetzt Mitglied bei Geras24 Up. '
        },
        {
          question: 'Mein Profil',
          answer: 'Hier kannst du deine persönlichen Daten pflegen und Einstellungen zur Sicherheit deines Kontos vornehmen.'
        },
        {
          question: 'Wie steht es mit der Sicherheit?',
          answer: 'Durch Einrichtung einer Zwei-Faktor-Authentifizierung kannst du deinen Account optimal schützen so dass garantiert nur du Zugriff auf deine Dateien hast.<br>Deine Daten werden sicher per SSL-Verschlüsselung auf die Server geladen und können nur von dir eingesehen werden.'
        },
        {
          question: 'Wo werden meine Daten gespeichert?',
          answer: 'Deine Daten werden DSGVO-konform auf unseren Hochsicherheits-Servern mit Standort Deutschland abgelegt. Die von uns genutzten Rechenzentren entsprechen den höchsten Datenschutz- und Sicherheitsstandards.'
        },
        {
          question: 'Wer kann meine gespeicherten Daten sehen?',
          answer: 'Niemand ohne deine ausdrückliche Einwilligung. Sobald du die Zwei-Faktor-Authentifizierung benutzt, benötigt der Kundenservice dein Passwort. Dieses wird immer automatisch auf deinem Endgerät erneuert. Auch gelöschte Daten können wir nicht wiederherstellen. Geras24 ist ein sicherer Ort, an dem du deine Daten ohne Vorbehalte hinterlegen und speichern kannst.'
        },
        {
          question: 'Die Vertrauensperson',
          answer: 'Nur von dir berechtigte Personen, denen du vertraust, erhalten im Bedarfsfall Zugang. Die von dir bestimmten Vertrauenspersonen hinterlegst du in deinem Profil. Mittels der Sterbeurkunde und eines Videocalls wird ihnen der Zugriff auf deine Unterlagen gewährt.'
        },
        {
          question: 'Welche Datei-Formate und Größen sind erlaubt?',
          answer: 'Folgende Formate werden akzeptiert:\n' +
              '\n' +
              ' \n' +
              ' \n' +
              '<br><br>Dokumente: .pdf .doc, .docx .pages .ppt, .pptx .xls, .xlsx\n' +
              '<br>' + 'Fotos: .jpg .jpeg .png\n' +
              '<br>' + 'Audio: .mp3 .m4a .wav\n' +
              '<br>' + 'Video: .mpeg .wmv .mov .avi .swf' +
              '<br><br>' + 'Die maximale Dateigröße beträgt 500 MB.'
        },
        {
          question: 'Kann ich meinen Account löschen?',
          answer: 'Selbstverständlich kannst du dein Konto jederzeit löschen sowie dein Abonnement kündigen. Wende dich dafür an unseren Kundenservice. Deine Daten kannst du natürlich vorab herunterladen.'
        },
        {
          question: 'Wie kann ich bezahlen?',
          answer: 'Wir bieten alle gängigen Zahlungsmöglichkeiten wie Paypal, Kreditkartenzahlung und Sofortüberweisung an. Deine Daten werden sicher per SSL übermittelt. Wir speichern keinerlei Zahlungsinformationen von dir.'
        },
      ],
      showLogin: false,
      showRegister: false,
    }
  },
  created() {
    this.setMetaTags()
  },
}
</script>
