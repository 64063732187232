<template>
    <section>
      <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true" />

        <v-row align="center">
          <v-col
              cols="12"
              md="6"
              align="center"
              justify="center"
              class="header-col"
          >
            <div class="header-text">
              <h1>{{ projectData.title }}</h1>
              <div class="main-font">
                {{ projectData.header_text }}
              </div>
              <div class="pt-5">
                <v-btn
                    v-if="!$store.getters.isLoggedIn"
                    elevation="5"
                    min-width="0"
                    x-large
                    color="primary"
                    @click="showRegister = true"
                >
                  <span>Kostenlos registrieren</span>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col
              cols="12"
              md="6"
          >
            <v-img v-if="projectData.image" :src="projectData.image" width="100%" height="calc(100vh - 90px)"></v-img>
          </v-col>
        </v-row>

      <Footer
          :showLogin="showLogin"
          :showRegister="showRegister"
          @close-login-modal="showLogin = false"
          @close-register-modal="showRegister = false"
      />

    </section>
</template>

<script>
import TextWithImage from "../../widgets/TextWithImage.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";
import VideoPlayer from "../../widgets/VideoPlayer.vue";

export default {
    components:{
      TextWithImage,
      Header,
      Footer,
      LoginDialog,
      RegisterDialog,
      VideoPlayer
    }, 
    data(){
        return {
          projectData: {},
          products: [
            {
              name: 'persoenliche-botschaft',
              title: this.$root.$t('texts.products.personalMessage.introTitle'),
              header_text: this.$root.$t('texts.products.personalMessage.introDescription'),
              description: this.$root.$t('texts.products.personalMessage.description'),
              image: '/assets/img/home/p_persoenliche_botschaft.jpg'
            },
            {
              name: 'checkliste',
              title: this.$root.$t('texts.products.checklist.introTitle'),
              header_text: this.$root.$t('texts.products.checklist.introDescription'),
              description: this.$root.$t('texts.products.checklist.description'),
              image: '/assets/img/home/p_checkliste.jpg'
            },
            {
              name: 'tresor',
              title: this.$root.$t('texts.products.digitalSafe.introTitle'),
              header_text: this.$root.$t('texts.products.digitalSafe.introDescription'),
              description: this.$root.$t('texts.products.digitalSafe.description'),
              image: '/assets/img/home/p_tresor.jpg'
            },
            {
              name: 'der-letzte-wunsch',
              title: this.$root.$t('texts.products.lastWish.introTitle'),
              header_text: this.$root.$t('texts.products.lastWish.introDescription'),
              description: this.$root.$t('texts.products.lastWish.description'),
              image: '/assets/img/home/p_der_letzte_wunsch.jpg'
            },
            {
              name: 'up',
              title: 'Geras24 Up',
              header_text: 'Das Up ist das Herzstück von Geras24 - Up ist die Kommunikationsplattform der Zukunft. Im Up können deine Freunde nach deinem Tod miteinander kommunizieren. Jeder hat die Möglichkeit in dein Kondolenzbuch eine persönliche Widmung einzutragen. Im Up kannst du auch nach deinem Tod digital weiterleben. Du willst mehr erfahren? Dann werde jetzt Mitglied im Geras24 Up.',
              description: 'Soziale Medien bieten uns die Möglichkeit, Erlebnisse, Erfahrungen und Augenblicke mit den Menschen zu teilen, denen wir uns verbunden fühlen – unabhängig von Zeit und Ort. Mit dem digitalen Himmel bei Geras24 wollen wir ebenso eine solche Verbundenheit schaffen. Up ist ein Raum der liebevollen und dankbaren Erinnerung. Der digitale Himmel kann mit Dingen gefüllt werden, die wir lieben. Mit Bildern und Erlebnissen, die uns ausmachen. Wie möchten wir in Erinnerung behalten werden? Was soll für uns bleiben? Was möchten wir unseren Lieben hinterlassen und welchen Rat noch mitgeben?' +
                  'Menschen, die wir zurücklassen, geben wir mit Up einen Ort, an dem man sich zusammen erinnern kann – sei es, um gemeinsam Erlebtes zu teilen oder in der Gemeinschaft zu gedenken. So kann am Trauertag beispielsweise eine Kerze angezündet oder sich in Dankbarkeit einander zugeprostet werden – jeder dort, wo er ist, und doch verbunden in Gedanken.',
              image: '/assets/img/home/p_up.jpg'
            }
          ],
          showLogin: false,
          showRegister: false
        }
    },
    created() {
      this.metaTags()
    },
    mounted() {
      this.getData()
    },
    watch: {
      // Re-fetch when route changes
      '$route': 'getData'
    },
    methods: {
      metaTags() {
        document.title = this.$t('meta_tags')['products'][this.$route.params.product].title

        const descEl = document.querySelector('head meta[name="description"]');
        descEl.setAttribute('content', this.$t('meta_tags')['products'][this.$route.params.product].description);
      },
      getData() {
        this.metaTags()

        this.projectData = this.products.find(obj => {
          return obj.name === this.$route.params.product
        })
      }
    }
}
</script>
