<template>
    <v-container
        fluid
        tag="section"
    >
        <!-- Main Section -->
        <v-row justify="center" class="px-8 lt-3 pb-8">
            <v-col cols="12" md="12">
                <p class="text-h5 main-font-bold main-title-color">{{ $t('subscriptions') }}</p>
                <div class="subtitle-1 font-weight-light main-grey">
                    {{ $t('subscriptions_subtitle') }}
                </div>
            </v-col>

        </v-row>
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="10">
                        <v-autocomplete
                            v-model="newUser"
                            :loading="isLoading"
                            :items="items"
                            :search-input.sync="search"
                            hide-no-data
                            :disabled="addUserLoading"
                            color="primary"
                            item-text="name"
                            item-value="id"
                            :label="$t('name_username')"
                            :placeholder="$t('start_type_to_search')"
                            prepend-icon="mdi-account-search"
                            return-object
                            outlined
                            light
                        >
                            <template v-slot:item="data">
                                <v-list-item-avatar>
                                    <img :src="data.item.avatar ? data.item.avatar : '/assets/img/avatar.png'">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                    <v-list-item-subtitle>@{{ data.item.username }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn x-large color="primary" :disabled="!newUser" :loading="addUserLoading" @click="addUser">
                            <v-icon>mdi-plus</v-icon> {{ $t('add') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" class="mt-5">
                <v-row v-if="!loading">
                    <v-col cols="12" md="3" v-for="(duplication, i) in duplications" :key="i">
                        <v-hover v-slot="{hover}">
                            <v-card elevation="5" :color="hover ? 'primary' : 'white'">
                                <v-card-text align="center" justify="center">
                                    <v-avatar size="70" class="mt-10">
                                        <v-img :src="duplication.user.avatar ? duplication.user.avatar : '/assets/img/avatar.png'">
                                            <template v-slot:placeholder>
                                                <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                                >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </v-avatar>
                                </v-card-text>
                                <v-card-text align="center" justify="center">
                                    <div class="text-subtitle-1" :class="{'white--text': hover}">{{ duplication.user.name }}</div>
                                    <div class="text-caption" :class="{'white--text': hover}">@{{ duplication.user.username ? duplication.user.username : '----' }}</div>

                                </v-card-text>
                                <v-card-text align="center" justify="center">
                                    <v-row class="pa-2">
                                        <div class="text-subtitle-1" :class="{'white--text': hover}">{{ $t('created_at') }}</div>
                                        <v-spacer></v-spacer>
                                        <div class="text-subtitle-1" :class="{'white--text': hover}">{{ duplication.starts_on}}</div>
                                    </v-row>
                                </v-card-text>
                                <v-card-text align="center" justify="center">
                                    <v-row class="pa-2">
                                        <div class="text-subtitle-1" :class="{'white--text': hover}">{{ $t('expired_at') }}</div>
                                        <v-spacer></v-spacer>
                                        <div class="text-subtitle-1" :class="{'white--text': hover}">{{ duplication.expires_on}}</div>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </v-col>
                    <v-col v-if="!duplications.length" justify="center" align="center" cols="12">
                        <v-icon x-large color="grey">mdi-alert-rhombus-outline</v-icon>
                        <div class="text-subtitle-1 mt-5 main-grey">{{ $t('empty_duplications') }}</div>
                    </v-col>
                </v-row>
                <v-row v-else class="mb-5 mt-5" :key="0">
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-row>
            </v-col>

            <transition name="slide" mode="out-in">
                <v-col cols="12" class="mt-10" v-if="selectedUser">
                    <v-row>
                        <!-- Trusted User Informations -->
                        <v-col cols="9">
                            <v-row>
                                <v-col cols="12">
                                    <div class="text-h6 main-font-bold main-title-color">{{ selectedUser.name }}</div>
                                    <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0">{{ $t('personal_information') }}</div>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('name') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.name }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('gender') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.gender || '---' }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('martial_status') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.martial_status == 1 ? 'Verheiratet' : selectedUser.martial_status == 0 ? 'Single' : '---' }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('email') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.email }}</div>
                                        </v-col>

                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('address') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.address || '---' }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('nationality') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.nationality || '---' }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('age') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.age  ? selectedUser.age : '--' }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <div class="text-subtitle-1 main-title-color">{{ $t('phone') }}</div>
                                            <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0">{{ selectedUser.phone || '---' }}</div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </transition>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            duplications: null,

            activeTab: false,
            selectedUser: null,

            newUser: null,
            isLoading: false,
            search: null,
            addUserLoading: false,
            items: [],
        };
    },
    mounted(){
        this.getDuplications()
    },
    methods: {
        getDuplications(){
            this.loading = true
            this.$store.dispatch('getUserDuplications')
            .then(resp => {
                this.duplications = resp
                this.newUser = null,
                this.isLoading = false,
                this.search = null,
                this.addUserLoading = false,
                this.items = [],
                this.loading = false
            })
            .catch(err => {
                this.$helpers.showError(err)
            })
        },

        addUser(){
            this.isLoading = true,
            this.addUserLoading = true,
            this.$store.dispatch('addDuplicationUser', { user_id: this.newUser.id })
            .then(resp => {
                this.duplications = resp
                this.getDuplications()
            })
            .catch(err => {
                this.isLoading = false
                this.addUserLoading = false
                if (err.response.status === 402) this.paymentRequireDialog = true
                else this.$helpers.showError(err)
            })
        }
    },
    watch:{
        search(value){
            // Items have already been requested
            if (this.isLoading) return
            if (value.length <= 3) return
            this.isLoading = true

            this.$store.dispatch('searchAllUser', value)
            .then(resp => {
                this.isLoading = false
                this.items = resp
            })
            .catch(err => {
                this.isLoading = false
                this.$helpers.showError(err)
            })
        }
    },
};
</script>
