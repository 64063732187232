var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-8",attrs:{"fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{staticClass:"pt-8 pl-3 pr-3 pb-8",attrs:{"cols":"9"}},[_c('p',{staticClass:"text-h5 main-font-bold main-title-color mb-0"},[_vm._v(_vm._s(_vm.$t('support')))])]),_c('v-col',{staticClass:"pt-8 pl-3 pr-3 pb-8",attrs:{"align":"right","justify":"end","cols":"3"}},[_c('router-link',{attrs:{"to":{ name: 'newSupport' }}},[_c('v-btn',{attrs:{"large":"","color":"white"}},[_c('span',{staticClass:"primary--text"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('new_ticket')))],1)])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tickets,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('loading'),"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
            'items-per-page-text': _vm.$t('row_per_page')
        }},scopedSlots:_vm._u([{key:"item.tracking_code",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{staticClass:"primary--text",attrs:{"to":{ name: 'showSupport', params: { id: item.id } }}},[_vm._v(_vm._s(item.tracking_code)+" ")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","fab":"","color":"primary"}},[_c('v-icon',{on:{"click":function($event){return _vm.$router.push({ name: 'showSupport', params: { id: item.id } })}}},[_vm._v(" mdi-eye ")])],1)]}}])},[_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t('empty_data'))+" ")])],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }