<template>
  <v-container
      fluid
      tag="section"
      class="pt-3 px-8 pb-8"
  >
    <v-row>
      <v-col cols="9" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold">{{ $t('upload_new_file') }}</p>
      </v-col>
      <v-col cols="3" align="right" justify="end" class="pt-8 pl-3 pr-3 pb-8">
        <router-link :to="{ name: 'advisorIndex' }">
          <v-btn color="white" fab>
            <span class="primary--text"><v-icon>mdi-arrow-right</v-icon></span>
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-form v-if="!uploading">
          <v-row>
            <v-col cols="12" md="6">
              <v-file-input
                  v-model="file"
                  color="primary darken-1"
                  :label="$t('new_file')"
                  :placeholder="$t('select_file')"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :disabled="uploading"
                  :show-size="1024"
                  :error="!file && fileError"
              >
              </v-file-input>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  outlined
                  :label="$t('file_desc')"
                  prepend-icon="mdi-card-text-outline"
                  maxlength="200"
                  counter
                  :disabled="uploading"
                  v-model="desc"
                  :error="!desc && descError"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                justify="right"
                align="right"
            >
              <v-btn
                  large
                  :loading="uploading"
                  :disabled="uploading"
                  color="primary"
                  class="ma-2 white--text px-10"
                  :style="{'width': $vuetify.breakpoint.smAndDown ? '100%' : ''}"
                  @click="uploadFile"
              >
                {{ $t('upload') }}
                <v-icon
                    right
                    dark
                >
                  mdi-cloud-upload
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-progress-linear v-else indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      desc: null,
      descError: false,
      fileError: false,
      file: null,
      uploading: false,
    };
  },
  methods: {
    uploadFile() {
      if (!this.file) {
        this.fileError = true
        setTimeout(() => {
          this.fileError = false
        }, 2000);
        return
      }
      if (!this.desc) {
        this.descError = true
        setTimeout(() => {
          this.descError = false
        }, 2000);
        return
      }
      this.uploading = true
      var formData = new FormData();
      formData.append('file', this.file, this.file.name);
      formData.append('desc', this.desc);
      formData.append('category_id', this.$route.params.category_id);

      this.$store.dispatch('uploadAdvisorFile', {data: formData})
          .then(() => {
            Vue.$toast.success(this.$t('file_upload_success'))
            this.$router.push({name: 'advisorIndex', params: {category_id: this.$route.params.category_id}})
          })
          .catch(err => {
            this.uploading = false
            this.$helpers.showError(err)
          })
    },
  }
};
</script>
