<template>
  <v-container fluid tag="section" class="pt-3 px-8 pb-8">
    <!-- Head Buttons -->
    <v-row>
      <v-col cols="12" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold">
          <span class="pr-5">{{ $t('advisor_title') }}</span>
          <span class="d-inline">
          <v-btn
              class="btn-help-video"
              elevation="5"
              small
              href="https://www.alleato.eu"
              target="_blank"
          >
              <v-icon small color="white">mdi-help-circle</v-icon>
            </v-btn>
          </span>
        </p>
        <p class="text-subtitle-1">{{ $t('advisor_subtitle') }}</p>
        <v-row>
          <v-col cols="12" sm="6" md="3" v-for="(partner, index) in partners" :key="index">
            <v-hover v-slot="{ hover }">
              <v-card
                  class="pa-5"
                  height="100%"
                  :elevation="hover ? 7 : 5"
                  :href="partner.target"
                  :target="partner.target.includes('http') ? '_blank' : ''"
              >
                <v-img
                    :src="partner.logo"
                    :alt="partner.name"
                    :max-height="50"
                    :contain="true"
                />
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Category -->
    <v-row class="mt-5" v-if="!trustedPanel">
      <v-col cols="12" v-if="(categories ? categories.length : false) && store.getters.secondUser" align="right">
        <v-btn color="white" @click="newCategoryDialog = true" :style="{'width': $vuetify.breakpoint.smAndDown ? '100%' : ''}">
          <v-icon color="primary">mdi-plus</v-icon>
          Kategorie hinzufügen
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-tabs color="primary darken-1" show-arrows :grow="categories.length > 4" @change="getFiles" v-if="(categories ? categories.length : false)" v-model="categoryIndex">
          <v-tab class="white" v-for="c in categories" :key="c.id" @click="category = c.id; medias = null;">
            <v-toolbar class="elevation-0 white" flat dense color="white">
              {{ c.name }}
              <v-btn
                  small
                  icon
                  class="ml-5"
                  v-if="category == c.id && store.getters.secondUser"
                  @click="deleteCategoryConfirmDialog = true; deleteCategoryLoading = c.id"
                  :loading="deleteCategoryLoading == c.id"
                  :disabled="deleteCategoryLoading == c.id"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn small icon v-if="category == c.id && store.getters.secondUser" @click="newCategory = c; newCategoryDialog = true;">
                <v-icon>mdi-circle-edit-outline</v-icon>
              </v-btn>
            </v-toolbar>
          </v-tab>
        </v-tabs>
        <v-col cols="12" align="center" v-else-if="!loading && !paymentRequireDialog">
          <v-btn large color="white" @click="newCategoryDialog = true" class="mr-2">
            <span class="primary--text"><v-icon>mdi-plus</v-icon> {{ $t('create_new_category') }}</span>
          </v-btn>
        </v-col>
      </v-col>
    </v-row>

    <!-- Medias -->
    <transition name="slide" mode="out-in">
      <v-row v-if="!loading && ((categories ? categories.length : false) || trustedPanel)" class="mt-10" :key="1">
        <v-col cols="12" v-if="files ? files.length : false">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">{{ $t('name') }}</th>
                <th class="text-left">{{ $t('description') }}</th>
                <th class="text-left">{{ $t('size') }}</th>
                <th class="text-left">{{ $t('date') }}</th>
                <th class="text-center">{{ $t('actions') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(file, i) in files" :key="file.id">
                <td>{{ i + 1 }}</td>
                <td>{{ file.name }}</td>
                <td>{{ file.desc }}</td>
                <td>{{ getSize(file.size) }}</td>
                <td>{{ file.created_at }}</td>
                <td class="text-center">
                  <v-btn v-if="!trustedPanel" medium text fab color="grey" @click="deleteAdvisorFile(file.id)" :loading="deleteTresorFileLoading == file.id" :disabled="deleteTresorFileLoading == file.id">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <a :href="file.url" v-auth-href="{
                                            textMode: 'html',
                                            downloadingHtml: '<v-icon>mdi-loading</v-icon>',
                                        }">
                    <v-btn medium text fab color="grey">
                      <v-icon>mdi-cloud-download</v-icon>
                    </v-btn>
                  </a>
                  <v-btn v-if="!trustedPanel && false" medium text fab color="primary darken-1" @click="getFileTrustedUsers(file)" :loading="getFileTrustedUsersLoading == file.id" :disabled="getFileTrustedUsersLoading">
                    <v-icon>mdi-key-variant</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <!-- Add New File Button -->
        <transition name="slide" mode="out-in">
          <v-col v-if="!trustedPanel && category" justify="center" align="center" cols="12" class="mt-10 mb-16">
            <router-link :to="{name: 'newAdvisor', params: {category_id: category}}">
              <v-icon x-large color="primary">mdi-cloud-upload-outline</v-icon>
            </router-link>
            <p class="text-subtitle-1 mt-5">{{ $t('upload_new_file') }}</p>
          </v-col>
          <v-col v-else-if="trustedPanel && !files.length" class="mt-10 mb-16" justify="center" align="center" cols="12">
            <v-icon x-large color="grey">mdi-alert-rhombus-outline</v-icon>
            <h1 class="mt-5 text--grey text-subtitle-2">{{ $t('no_file') }}</h1>
          </v-col>
        </transition>
      </v-row>
      <v-row v-else-if="(categories ? categories.length : true)" class="mb-5 mt-5" :key="0">
        <v-col cols="12">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-col>
      </v-row>
    </transition>

    <!-- Delete File Confirm Dialog -->
    <v-dialog
        v-model="deleteConformDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('are_you_sure') }}
        </v-card-title>

        <v-card-text>
          {{ $t('delete_file_alert') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="deleteAdvisorFile(selectedMedia)"
          >
            {{ $t('yes') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="deleteConformDialog = false; selectedMedia = null;"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- File Trusted Users Dialog -->
    <v-dialog
        v-model="fileTrustedUsersDialog"
        max-width="900"
    >
      <v-card>
        <v-card-text class="text--primary">
          <v-card-title class="pl-0 pr-0">
            {{ $t('trusted_users2') }}
            <v-spacer></v-spacer>
            <v-btn icon @click="fileTrustedUsersDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          {{ $t('trusted_users2_subtitle') }}
        </v-card-text>
        <v-card-text v-if="fileTrustedUsers">
          <v-row v-if="fileTrustedUsers.length">
            <v-col cols="12" md="4" v-for="user in fileTrustedUsers" :key="user.id">
              <v-card elevation="5">
                <v-card-text align="center" justify="center">
                  <v-avatar size="70">
                    <img :src="user.avatar ? user.avatar : '/assets/img/avatar.png'">
                  </v-avatar>
                </v-card-text>
                <v-card-text align="center" justify="center">
                  <div class="text-subtitle-1">{{ user.firstname }} {{ user.name }}</div>
                  <div class="text-caption">@{{ user.username ? user.username : '----' }}</div>
                </v-card-text>
                <v-card-text align="left">
                  <div class="text-caption">
                    <v-icon class="mr-2">mdi-clock-outline</v-icon>
                    Start at: {{ user.starts_at ? user.starts_at : 'From Death' }}
                  </div>
                  <div class="text-caption">
                    <v-icon class="mr-2">mdi-clock-outline</v-icon>
                    Expired at: {{ user.expires_at ? user.expires_at : 'To The End' }}
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                      color="grey"
                      text
                      fab
                      :disabled="deleteFileTrustedUserLoading"
                      :loading="deleteFileTrustedUserLoading == user.id"
                      @click="deleteFileTrustedUser(user.id)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"></v-divider>
          <v-row>
            <v-col justify="center" align="center" cols="12">
              <v-row>
                <v-col cols="12">
                  <span class="text-subtitle-1">{{ $t('add_new') }}</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                      hide-details
                      :disabled="addFileTrustedLoading || newFileTrustedGroup"
                      v-model="newFileTrustedUser"
                      :items="userItems"
                      :label="$t('trusted_user')"
                      prepend-icon='mdi-account-group'
                      color="primary"
                      outlined
                      clearable
                      light
                      @click="newFileTrustedGroup = null"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                      hide-details
                      :disabled="addFileTrustedLoading || newFileTrustedUser"
                      v-model="newFileTrustedGroup"
                      :items="groupItems"
                      :label="$t('trusted_group')"
                      prepend-icon='mdi-account-group'
                      color="primary"
                      outlined
                      clearable
                      light
                      @click="newFileTrustedUser = null"
                  ></v-select>
                </v-col>
                <!-- Starts From -->
                <v-col cols="12" md="6">
                  <v-menu
                      ref="start_at"
                      v-model="starts_date"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="newFileTrustedDate.start_at"
                          :label="$t('start_at')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          clearable
                          :hint="$t('start_at_hint')"
                          persistent-hint
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="newFileTrustedDate.start_at"
                        no-title
                        color="primary"
                        scrollable
                        first-day-of-week="1"
                        :min="minDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="starts_date = false"
                      >
                        {{ $t('close') }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <!-- Expired To -->
                <v-col cols="12" md="6">
                  <v-menu
                      ref="expire_at"
                      v-model="expired_date"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="newFileTrustedDate.expire_at"
                          :label="$t('expire_at')"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          :hint="$t('expire_at_hint')"
                          persistent-hint
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="newFileTrustedDate.expire_at"
                        color="primary"
                        no-title
                        scrollable
                        first-day-of-week="1"
                        :min="minDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="expired_date = false"
                      >
                        {{ $t('close') }}
                      </v-btn>

                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" align="right">
                  <v-btn
                      color="primary"
                      elevation="5"
                      :loading="addFileTrustedLoading"
                      :disabled="!newFileTrustedUser && !newFileTrustedGroup"
                      @click="addFileTrusted"
                      :style="{'width': $vuetify.breakpoint.smAndDown ? '100%' : ''}"
                  >
                    {{ $t('add') }}
                    <v-icon color="primary darken-1 ml-2">mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Create New Category -->
    <v-dialog
        v-model="newCategoryDialog"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('create_new_category') }}:
        </v-card-title>

        <v-card-text align="center" justify="center">
          <v-row class="mt-5">
            <v-col cols="8">
              <v-text-field
                  outlined
                  :label="$t('category_name')"
                  maxlength="200"
                  counter
                  :disabled="newCategoryLoading"
                  :loading="newCategoryLoading"
                  v-model="newCategory.name"
                  @keyup.enter="newCategory ? (newCategory.id ? updateCategory() : addNewCategory()) : null"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn
                  color="white"
                  elevation="5"
                  fab
                  :disabled="!newCategory || newCategoryLoading"
                  :loading="newCategoryLoading"
                  @click="newCategory.id ? updateCategory() : addNewCategory()"
              >
                <v-icon color="primary darken-1" v-if="newCategory.id">mdi-check</v-icon>
                <v-icon color="primary darken-1" v-else>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary darken-1"
              text
              @click="newCategoryDialog = false;"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Category Confirm Dialog -->
    <v-dialog
        v-model="deleteCategoryConfirmDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('are_you_sure') }}
        </v-card-title>

        <v-card-text>
          {{ $t('delete_category_alert') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="deleteCategory"
          >
            {{ $t('yes') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="deleteCategoryConfirmDialog = false; deleteCategoryLoading = false;"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Payment Required Dialog -->
    <v-dialog
        v-model="paymentRequireDialog"
        max-width="450"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('need_payment') }}
        </v-card-title>

        <v-card-text>
          {{ $t('need_payment_desc') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="error darken-1"
              text
              @click="$router.push({ name: 'profileInfo' })"
          >
            {{ $t('later') }}
          </v-btn>

          <v-btn
              color="primary darken-1"
              text
              @click="$router.push({ name: 'profilePlans' })"
          >
            {{ $t('buy_now') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import moment from 'moment'
import store from "../../../../store";

export default {
  computed: {
    store() {
      return store
    },
    minDate() {
      const today = new Date();
      today.setDate(today.getDate() + 1);
      return today.toISOString().split('T')[0];
    }
  },
  components: {
    moment
  },
  data() {
    return {
      trustedPanel: false, // true: files of trusting panels
      loading: true,
      files: null,

      deleteConformDialog: false,
      fileTrustedUsersDialog: false,

      selectedMedia: null,

      downloadTresorFileLoading: false,
      deleteTresorFileLoading: false,
      getFileTrustedUsersLoading: false,
      deleteFileTrustedUserLoading: false,

      fileTrustedUsers: null,
      usersLoading: true,
      groupsLoading: true,
      users: null,
      groups: null,

      newFileTrustedUser: null,
      newFileTrustedGroup: null,
      newFileTrustedDate: {
        start_at: null,
        expire_at: null,
      },
      starts_date: false,
      expired_date: false,

      userItems: null,
      groupItems: null,
      addFileTrustedLoading: false,

      selectedFile: null,

      category: null,
      categoryIndex: 0,
      categories: null,
      newCategory: {
        id: null,
        name: null
      },
      newCategoryDialog: false,
      newCategoryLoading: false,
      deleteCategoryConfirmDialog: false,
      deleteCategoryLoading: false,

      trustersMenu: false,
      trustersLoading: true,
      trusterUsers: null,
      truster: null,

      trustedMenuUsers: [],
      selectedTrustedUser: null,

      paymentRequireDialog: false,
      defaultCategories: [
        'Ratgeber & Informationen',
        'Sachversicherungen',
        'BU',
        'Pflege- und Lebensversicherung'
      ],
      partners: [
        {
          target: 'https://geras24.juradirekt.com',
          name: "JURA DIREKT GmbH",
          logo: `${store.getters.frontUrl}/assets/img/home/juradirekt.png`
        },
        {
          target: 'https://www.europa.de/vep-kunde/?vepnr=9404056',
          name: "Continentale Versicherungsverbund",
          logo: `${store.getters.frontUrl}/assets/img/home/continentale.png`
        },
        {
          target: 'https://safe-id.eu/discount/alleato',
          name: "Safe-iD.eu",
          logo: `${store.getters.frontUrl}/assets/img/home/safeid.png`
        },
        {
          target: 'https://www.idealgo.de/ruhestandsmeister?vermittler=62612VM&variante=Default&theme=nxt',
          name: "IDEAL Versicherung AG",
          logo: `${store.getters.frontUrl}/assets/img/home/ideal.png`
        },
      ],
      lastAction: null
    }
  },
  watch: {
    '$route.name'() {
      this.getMe()
    }
  },
  mounted() {
    this.getMe()
    this.getTrustedUsers()
    this.getTrustedGroups()
  },
  methods: {
    getMe() {
      this.loading = true
      this.$store.dispatch('getMe')
          .then(() => {
            this.getCategories()
          })
    },
    getFiles() {
      if (this.trustedPanel) {
        this.getConfidingFiles()
        return
      }
      this.loading = true
      this.$store.dispatch('getAdvisorFiles', this.category)
          .then(resp => {
            this.files = resp
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            if (err.response.status === 402) this.paymentRequireDialog = true
            else this.$helpers.showError(err)
          })
    },
    getTrustedUsers() {
      this.usersLoading = true
      this.$store.dispatch('getTrustedUsers')
          .then(resp => {
            this.users = resp
            this.usersLoading = false
            let items = []
            for (let index = 0; index < resp.length; index++) {
              const user = resp[index];
              items.push({text: `${user.first_name} ${user.last_name}`, value: user.id})
            }
            this.userItems = items
          })
    },
    getTrustedGroups() {
      this.groupsLoading = true
      this.$store.dispatch('getTrustedGroups')
          .then(resp => {
            this.groups = resp
            this.groupsLoading = false
            let items = []
            for (let index = 0; index < resp.length; index++) {
              const group = resp[index];
              items.push({text: group.name, value: group.id})
            }
            this.groupItems = items
          })
    },
    getConfidingFiles() {
      if (!this.trustedPanel) {
        this.getFiles()
        return
      }
      this.loading = true
      this.$store.dispatch('getAdvisorConfidingFiles', this.trustedPanel.id)
          .then(resp => {
            this.files = resp
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            if (err.response.status === 402) this.paymentRequireDialog = true
            else this.$helpers.showError(err)
          })
    },
    downloadTresorFile(file) {
      this.downloadTresorFileLoading = file.slug
      this.$store.dispatch('getAdvisorFile', file.slug)
          .then(resp => {
            this.downloadFile(resp, file.name)
          })
          .catch(err => {
            this.downloadTresorFileLoading = false
            if (err.response.status === 402) this.paymentRequireDialog = true
            else this.$helpers.showError(err)
          })
    },
    downloadFile(file, name) {
      var element = document.createElement('a');
      element.setAttribute('href', file);
      element.setAttribute('download', name);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      this.downloadTresorFileLoading = false
    },
    deleteAdvisorFile(id) {
      if (!this.selectedMedia) {
        this.selectedMedia = id
        this.deleteConformDialog = true
      } else {
        this.deleteTresorFileLoading = id
        this.fabLoader = id
        this.deleteConformDialog = false
        this.$store.dispatch('deleteAdvisorFile', {id: id})
            .then(() => {
              this.fabLoader = false
              this.deleteTresorFileLoading = false
              this.selectedMedia = null
              this.getFiles()
            })
            .catch(err => {
              this.deleteTresorFileLoading = false
              this.fabLoader = false
              if (err.response.status === 402) this.paymentRequireDialog = true
              else this.$helpers.showError(err)
            })
      }
    },
    getSize(size) {
      let s = Math.floor(size / 1024)
      let format = 'KB'
      if (s >= 1024) {
        s = Math.floor(s / 1024)
        format = 'MB'
      }
      if (s >= 1024) {
        s = Math.floor(s / 1024)
        format = 'GB'
      }
      return `${s} ${format}`
    },
    getFileTrustedUsers(file) {
      this.getFileTrustedUsersLoading = file.id

      this.$store.dispatch('getAdvisorFileTrustedUsers', file.id)
          .then(res => {
            this.fileTrustedUsers = res
            this.fileTrustedUsersDialog = true
            this.getFileTrustedUsersLoading = false
            this.selectedFile = file
          })
          .catch(err => {
            this.getFileTrustedUsersLoading = false
            this.$helpers.showError(err)
            this.selectedFile = null
          })
    },
    addFileTrusted() {
      this.addFileTrustedLoading = true

      let id = this.newFileTrustedUser ? this.newFileTrustedUser : this.newFileTrustedGroup

      this.$store.dispatch('setAdvisorFileTrustedUsers', {
        id: this.selectedFile.id,
        trusted_users: [{user_id: id}],
        start_at: this.newFileTrustedDate.start_at ? this.newFileTrustedDate.start_at.replaceAll('-', '/') : null,
        expire_at: this.newFileTrustedDate.expire_at ? this.newFileTrustedDate.expire_at.replaceAll('-', '/') : null,
      })
          .then(() => {
            this.getFileTrustedUsers(this.selectedFile)
            this.newFileTrustedUser = null
            this.newFileTrustedGroup = null
            this.addFileTrustedLoading = false
            this.newFileTrustedDate = {
              start_at: null,
              expire_at: null,
            }
          })
          .catch(err => {
            this.addFileTrustedLoading = false
            this.$helpers.showError(err)
          })
    },
    deleteFileTrustedUser(id) {
      if (confirm(this.$t('are_you_sure'))) {
        this.deleteFileTrustedUserLoading = id
        this.$store.dispatch('deleteAdvisorFileTrustedUsers', {
          id: this.selectedFile.id,
          trusted_users: [id],
        })
            .then(() => {
              this.deleteFileTrustedUserLoading = false
              this.getFileTrustedUsers(this.selectedFile)
            })
            .catch(err => {
              this.deleteFileTrustedUserLoading = false
              this.$helpers.showError(err)
            })
      }
    },
    changePanel(user) {
      this.trustersMenu = false
      if (!user) this.trustedPanel = false
      else this.trustedPanel = user

      // Get Confings Files
      if (this.trustedPanel) this.getConfidingFiles()
      // Get My Files
      else this.getFiles()
    },
    getCategories() {
      this.$store.dispatch('getCategories', 'advisor')
          .then(resp => {
            this.categories = resp
            if (resp.length && !this.category) {
              if (this.$route.params.category_id) {
                for (let index = 0; index < resp.length; index++) {
                  const cat = resp[index];
                  if (cat.id == this.$route.params.category_id) this.categoryIndex = index
                }
                this.category = this.$route.params.category_id
              } else {
                this.category = resp[0].id
                this.categoryIndex = 0
              }
              this.getFiles()
            }

            let first = false;

            // Create Default Categories
            if (!resp.length) {
              for (let index = 0; index < this.defaultCategories.length; index++) {
                const cat = this.defaultCategories[index];
                this.$store.dispatch('storeCategories', {
                  type: 'advisor',
                  name: cat
                })
              }

              first = true
            } else {
              this.loading = false
            }

            if (first) {
              setTimeout(() => {
                this.getCategories()
              }, 500);
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.status === 402) this.paymentRequireDialog = true
          })
    },
    addNewCategory() {
      this.newCategoryLoading = true
      this.$store.dispatch('storeCategories', {
        type: 'advisor',
        name: this.newCategory.name
      })
          .then(() => {
            this.newCategory = {
              id: null,
              name: null
            }
            this.newCategoryLoading = false
            this.newCategoryDialog = false
            this.getCategories()
          })
          .catch(err => {
            this.newCategoryLoading = false
            if (err.response.status === 402) this.paymentRequireDialog = true
            else this.$helpers.showError(err)
          })
    },
    updateCategory() {
      this.newCategoryLoading = true
      this.$store.dispatch('updateCategories', this.newCategory)
          .then(() => {
            this.newCategory = {
              id: null,
              name: null
            }
            this.newCategoryLoading = false
            this.newCategoryDialog = false
            this.getCategories()
          })
          .catch(err => {
            this.newCategoryLoading = false
            if (err.response.status === 402) this.paymentRequireDialog = true
            else this.$helpers.showError(err)
          })
    },
    deleteCategory() {
      this.$store.dispatch('deleteCategories', {
        id: this.category,
        type: 'advisor'
      })
          .then(() => {
            this.category = null
            this.deleteCategoryLoading = false
            this.deleteCategoryConfirmDialog = false
            this.getCategories()
          })
          .catch(err => {
            this.deleteCategoryLoading = false
            this.deleteCategoryConfirmDialog = false
            if (err.response.status === 402) this.paymentRequireDialog = true
            else this.$helpers.showError(err)
          })

    },
    getTrusters() {
      this.trustersLoading = true
      this.$store.dispatch('getTrusterUsers')
          .then(resp => {
            this.trusterUsers = resp
            this.trustersLoading = false
            let items = []
            for (let index = 0; index < resp.length; index++) {
              const user = resp[index];
              if (user.status != 'requested') items.push(user)
            }
            this.trustedMenuUsers = items
          })
    },
    checkLastAction() {
      if (this.lastAction) {
        switch (this.lastAction.action) {
          case 'delete':
            this.prvKeyFile ? this.deleteTresorFile(this.lastAction.payload) : this.getPrvPemDialog = true;
            break;
          case 'download':
            this.prvKeyFile ? this.downloadTresorFile(this.lastAction.payload) : this.getPrvPemDialog = true;
            break;
          case 'access':
            this.prvKeyFile ? this.getFileTrustedUsers(this.lastAction.payload) : this.getPrvPemDialog = true;
            break;
          case 'deleteCategory':
            this.prvKeyFile ? this.deleteCategory() : this.getPrvPemDialog = true;
            break;
        }
        this.lastAction = null
      }
    },
  }
}
</script>
