<template>
  <v-container fluid tag="section" class="pt-3 px-8 pb-8">

    <!-- Head Buttons -->
    <v-row>
      <v-col cols="12" class="pt-8 pl-3 pr-3 pb-8">
        <div class="text-h5 main-font-bold pb-10">
          <span class="pr-5">{{ $t('checklist_title') }}</span>
          <HelpVideo video="checkliste" title="Die Checkliste"></HelpVideo>
        </div>
        <div class="text-subtitle-1">{{ $t('checklist_subtitle') }}</div>
      </v-col>
      <v-col align="right" justify="end">
        <v-btn
            large
            color="primary"
            @click="$refs.html2Pdf.generatePdf()"
            :style="{'width': $vuetify.breakpoint.smAndDown ? '100%' : ''}"
        >
          <v-icon color="white">mdi-file-pdf</v-icon>
          Checkliste herunterladen
        </v-btn>
      </v-col>
    </v-row>

    <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        filename="checklist"
        :paginate-elements-by-height="1100"
        :pdf-quality="2"
        pdf-format="a3"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        :manual-pagination="false"
        ref="html2Pdf"
    >
      <!-- Medias -->
      <v-row class="mt-10 mr-3" slot="pdf-content">
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left font-weight-bold">{{ $t('checklist_checkliste') }}</th>
                <th class="text-left font-weight-bold">{{ $t('checklist_meldepflichtig') }}</th>
                <th class="text-left font-weight-bold">{{ $t('checklist_frist_nach_todesfall') }}</th>
                <th class="text-left font-weight-bold">{{ $t('checklist_besonderheiten') }}</th>
                <th class="text-left font-weight-bold">{{ $t('checklist_notizen') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in checklists" :key="i">
                <td class="main-title-color py-5 font-weight-bold" v-html="item.content.name"></td>
                <td class="py-5" v-html="item.content.meldepflichtig || ''"></td>
                <td class="py-5" v-html="item.content.frist_nach_todesfall || ''"></td>
                <td class="py-5" v-html="item.content.besonderheiten || ''"></td>
                <td class="py-5" v-if="item.note">
                  <div v-html="nl2br(item.note)"></div>
                  <div class="text-center">
                    <a @click="addNote(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </a>
                  </div>
                </td>
                <td class="py-5" v-else>
                  <v-btn small color="primary" @click="addNote(item)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </vue-html2pdf>

    <!-- Add Note Dialog -->
    <v-dialog
        v-model="addNoteDialog"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('add_note_to_checklist') }}:
        </v-card-title>

        <v-card-text align="center" justify="center">
          <v-row class="mt-5">
            <v-col>
              <v-textarea
                  outlined
                  :label="$t('note')"
                  maxlength="200"
                  counter
                  :disabled="addNoteDialogLoading"
                  :loading="addNoteDialogLoading"
                  v-model="note"
              ></v-textarea>
            </v-col>
          </v-row>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error darken-1"
              text
              @click="addNoteDialog = false;"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
              color="primary"
              elevation="5"
              fab
              small
              :disabled="!selectedItem || addNoteDialogLoading"
              :loading="addNoteDialogLoading"
              @click="setNote()"
          >
            <v-icon v-if="selectedItem.id">mdi-check</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import HelpVideo from "../../../../widgets/HelpVideo";
import CopyToClipboard from "vue-copy-to-clipboard";

export default {

  data() {
    return {
      checklists: null,
      loading: true,
      note: null,

      addNoteDialog: false,
      addNoteDialogLoading: false,
      selectedItem: {},

      htmlToPdfOptions: {
        margin: 0,

        filename: `checklist.pdf`,

        image: {
          type: 'jpeg',
          quality: 0.98
        },

        enableLinks: false,

        html2canvas: {
          scale: 1,
          useCORS: true
        },

      }
    }
  },
  components: {
    HelpVideo
  },
  mounted() {
    this.getChecklists()
  },
  methods: {
    getChecklists() {
      this.$store.dispatch('getChecklists')
          .then(resp => {
            this.checklists = resp
            this.loading = false
          })
          .catch(err => {
            this.$helpers.showError(err)
          })
    },

    addNote(data) {
      this.selectedItem = data,
          this.note = data.note,
          this.addNoteDialog = true
    },

    setNote() {
      this.addNoteDialogLoading = true
      this.$store.dispatch('setNote', {
        checklist_id: this.selectedItem.id,
        note: this.note
      })
          .then(resp => {
            this.note = null,
                this.selectedItem = {}
            this.addNoteDialogLoading = false
            this.addNoteDialog = false
            this.loading = true
            this.getChecklists()
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.addNoteDialogLoading = false
          })
    },

    nl2br(str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return '';
      }
      let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
  }
}
</script>
